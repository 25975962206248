import { Component, OnInit } from '@angular/core';
import {AlertController, ModalController, Platform, NavController, ToastController} from '@ionic/angular';
import {ApiService} from "../../services/api.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import * as Clipboard from 'clipboard/dist/clipboard.min.js';
import {RecentPostInterface} from "../../../interfaces/RecentPostsInterface";
import {printGroup, SocialService} from "../../services/providers.service";
import {DomSanitizer} from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-media',
  templateUrl: './media.page.html',
  styleUrls: ['./media.page.scss'],
})
export class MediaPage implements OnInit {

  id: number;
  media: any;
  status: string = null;
  title: string = 'Media';
  campaign_platform: string = 'instagram';
  swipe_link: string = null;
  tags: string = null;
  campaign_id: number = null;
  is_story: boolean;
  is_finish: boolean = false;
  is_selected: boolean = false;
  add_media: boolean = false;
  edit_caption: boolean = false;
  choose_post: boolean = false;
  upload_story: boolean = false;
  upload_insight: boolean = false;
  insight_campaign: boolean = false;
  post_text: string = null;
  refuse_reason: string = null;
  media_refused: boolean = false;
  caption_saved: boolean = false;
  override_caption: boolean = false;
  all_media_refused: boolean = false;
  selected_media_refuse_reason: string = null;
  selected_media_index: number = null;
  story_image: string = null;
  insight_image: string = null;
  facebook_link: string = null;
  clipboard: any = null;
  clipboard2: any = null;
  clipboard3: any = null;
  postForm: FormGroup;
  storyForm: FormGroup;
  insightForm: FormGroup;
  livePosts: RecentPostInterface[];
  selectedPost: RecentPostInterface;
  constructor(
      public navCtrl: NavController,
      private apiService: ApiService,
      private socialService: SocialService,
      private alertCtrl: AlertController,
      public formbuilder: FormBuilder,
      private translate: TranslateService,
      private modalCtrl: ModalController,
      private platform: Platform,
      public sanitizer: DomSanitizer,
      private route: ActivatedRoute,
      public toastCtrl: ToastController,) {
      this.id = this.route.snapshot.params['id'];
      this.clipboard = new Clipboard('#cpyBtn');
      this.clipboard.on('success', () => this.showMsg(toastCtrl));
      this.clipboard2 = new Clipboard('#linkBtn');
      this.clipboard2.on('success', () => this.showMsg(toastCtrl));
      this.clipboard3 = new Clipboard('#tagsBtn');
      this.clipboard3.on('success', () => this.showMsg(toastCtrl));


  }
  async showMsg(toastCtrl: ToastController) {
      let toast = await toastCtrl.create({
          message: this.translate.instant('post-modal.text-copied'),
          duration: 3000,
          position: 'bottom'
      });
      toast.present();
  }
  declinedMedia(reason, image, is_video, index) {
      if (reason){
          if (reason=='INTERNAL_MEDIA_REFUSAL') {
              this.selected_media_refuse_reason = this.translate.instant('post-modal.internal-refuse-reason');
          } else {
              this.selected_media_refuse_reason = reason;
          }

          this.selected_media_index = index;
      } else {
          this.selected_media_index = null;
          this.selected_media_refuse_reason = null;

      }
  }

  ionViewDidEnter () {
    this.doRefresh();
  }

  ionViewWillLeave() {
      if (this.clipboard){
          this.clipboard.destroy();
      }
      if (this.clipboard2){
          this.clipboard2.destroy();
      }
      if (this.clipboard3){
          this.clipboard3.destroy();
      }
  }

  handleFileSelect(evt, type) {
      this.is_selected = true;
      var files = evt.target.files;
      var file = files[0];
      if (files && file) {
          var reader = new FileReader();
          if (type === 'story') {
              reader.onload = this._handleStoryLoaded.bind(this);
          } else {
              reader.onload = this._handleInsightLoaded.bind(this);

          }
          reader.readAsDataURL(file);
      }
  }
  _handleStoryLoaded(readerEvt) {
      this.setStoryImage(readerEvt.currentTarget.result);
  }

  _handleInsightLoaded(readerEvt) {
      this.setInsightImage(readerEvt.currentTarget.result);
  }

  private setInsightImage(image64) {
      this.insight_image = image64;
      this.insightForm.get('insight').setValue(this.insight_image);
  }

  private setStoryImage(image64) {

      this.story_image = image64;
      this.storyForm.get('story_image').setValue(this.story_image);
  }

  async goBack(){
      if ( ! this.is_story && this.status == 'START' && this.media.length && ! this.caption_saved){
          let title = this.translate.instant   ('media.caption-missing.title'),
              message = this.translate.instant ('media.caption-missing.subTitle'),
              confirm = this.translate.instant ('media.caption-missing.confirm'),
              alert = await this.alertCtrl.create({
                  header: title,
                  subHeader: message,
                  buttons: [
                      { text: confirm }
                  ],
                  mode: 'ios'
              });
          alert.present();
          return false;

      }
      if (this.caption_saved && this.status == 'START' && ! this.media.length){
          let title = this.translate.instant   ('media.media-missing.title'),
              message = this.translate.instant ('media.media-missing.subTitle'),
              confirm = this.translate.instant ('media.media-missing.confirm'),
              alert = await this.alertCtrl.create({
                  header: title,
                  subHeader: message,
                  buttons: [
                      { text: confirm }
                  ],
                  mode: 'ios'
              });
          alert.present();
          return false;

      }
      this.navCtrl.navigateBack('/campaign/'+this.campaign_id);
    }

  setFacebookLink(facebookLink){
      this.facebook_link = facebookLink.value;
  }
  doRefresh(){
      this.apiService.getMedia(this.id).then(
          (su: any) => {
              this.media = su.data;
              this.title = su.title;
              this.campaign_platform  = su.platform;
              this.is_story = su.is_story == '1';
              this.campaign_id = su.campaign_id;
              if (su.post_text){
                  this.post_text = su.post_text;
              }
              if (su.swipe_link){
                  this.swipe_link = su.swipe_link;
              }
              if (su.tags){
                  this.tags = su.tags;
              }
              this.postForm = this.formbuilder.group({
                  post_text: [this.post_text],
              });

              this.storyForm = this.formbuilder.group({
                  story_image: [this.story_image],
                  views_count: [null]
              });
              if (this.is_story) {
                  this.insightForm = this.formbuilder.group({
                      story_views: [null, Validators.required],
                      insight: [null, this.insight_image],
                      impressions: [null, Validators.required],
                  });
              } else {
                  this.insightForm = this.formbuilder.group({
                      reach: [null, Validators.required],
                      insight: [null, this.insight_image],
                      impressions: [null, Validators.required],
                  });

              }
              switch (su.status){
                  case 0:
                  case '0':
                      this.status = 'START';
                      this.add_media = true;
                      if ( ! this.is_story){
                          this.edit_caption = true;
                      }
                      break;
                  case 1:
                  case '1':
                      this.status = 'PENDING_MANAGER';
                      this.add_media = true;
                      if ( ! this.is_story){
                          this.edit_caption = true;
                      }
                      break;
                  case 2:
                  case '2':
                      this.status = 'PENDING_CUSTOMER';
                      break;
                  case 3:
                  case '3':
                      this.status = 'CAPTION_DECLINED';
                      if ( ! this.is_story){
                          this.edit_caption = true;
                          this.refuse_reason = su.refuse_reason;
                      }
                      break;
                  case 4:
                  case '4':
                      this.status = 'MEDIA_DECLINED';
                      this.add_media = true;
                      this.media_refused = true;
                      break;
                  case 5:
                  case '5':
                      this.status = 'DECLINED';
                      if ( ! this.is_story){
                          this.edit_caption = true;
                          this.refuse_reason = su.refuse_reason;
                      }
                      this.add_media = true;
                      this.media_refused = true;
                      break;
                  case 6:
                  case '6':
                      if (su.campaign_insights && ! su.ask_insight){
                          this.insight_campaign = true;
                      }
                      if (su.ask_insight){
                          this.upload_insight = true;
                      }
                      if (this.is_story){
                          if (! su.campaign_insights){
                              this.upload_story = true;
                              this.status = 'STORY_APPROVED';
                          } else {
                              this.status = 'PENDING_STORY_INSIGHTS';
                          }
                      } else {
                          this.status = 'POST_APPROVED';

                          this.choose_post = true;
                          this.socialService.getRecentUserPosts(this.campaign_platform, su.type === 'reels' ? 'reels' : 'all').then(
                              su => {
                                  this.livePosts = this.campaign_platform === 'facebook' ? '' : su['data']
                              },
                              er => console.log("------ ERROR GETTING POSTS", er)
                          ).catch(er => console.log("------ ERROR CATCH GETTING POSTS", er));

                      }
                      break;
                  case 7:
                  case '7':
                      this.status = 'LIVE';
                      break;
                  case 8:
                  case '8':
                      if (su.campaign_insights && ! su.ask_insight){
                          this.insight_campaign = true;
                      }
                      if (su.ask_insight){
                          this.upload_insight = true;
                      }
                      if (this.is_story){
                          if (! su.campaign_insights){
                              this.upload_story = true;
                              this.status = 'PENDING_STORY_VIEWS';
                          } else {
                              this.status = 'PENDING_STORY_INSIGHTS';
                          }
                      } else {
                          this.status = 'PENDING_POST_INSIGHTS';
                      }
                      break;
              }

              let refuses_count = 0;
              this.media.forEach((post_media, i) => {
                  if ((post_media.approved == 1 || post_media.approved == '1') && this.status == 'PENDING_MANAGER' ){
                      this.add_media = false;
                      this.status = 'PENDING_MANAGER_CAPTION';
                  }
                  if (post_media.approved == -1 || post_media.approved == '-1'){
                      refuses_count++;
                  }
                  if (post_media.is_video == 1 || post_media.is_video == '1'){
                      this.media[i].url = this.sanitizer.bypassSecurityTrustResourceUrl(post_media.url)
                  }


                  if (this.media_refused && this.selected_media_index === null && (post_media.approved == -1 || post_media.approved == '-1')){
                      this.selected_media_index = i;
                      this.selected_media_refuse_reason = post_media.refuse_reason;
                  }

              });
              this.all_media_refused = refuses_count === this.media.length;
          },
          err => {

          },
      );
  }

  ngOnInit() {
  }

  mediaStatus(status) {
      switch (status){
          case 1:
          case '1':
              return  'media_approved';
          case -1:
          case '-1':
              return 'media_declined';
          case  '0':
          case 0:
          case  '2':
          case 2:
              return 'media_pending';
      }
  }
  finishPost(){
      let data = {campaigns_post_id: this.id};
      if (this.is_story)
          Object.assign(data, {
              story_image: this.storyForm.get('story_image').value,
              stroy_views: this.storyForm.get('views_count').value
          });
      else if (this.campaign_platform === 'facebook'){
          Object.assign(data, {post_link: this.facebook_link});
      } else {
          Object.assign(data, {post_link: this.selectedPost.link, post_id: this.selectedPost.id});
      }
      this.apiService.doneMedia(data).then(
          su => {
              if ( document.URL.indexOf("/post/") > 0){
                  this.navCtrl.navigateRoot('/campaign/'+ this.campaign_id);
              } else {
                  this.navCtrl.pop();
              }
          },
          async error2 => {
              let title = this.translate.instant('media.invalid-link.title'),
                  message = this.translate.instant('media.invalid-link.message'),
                  ok = this.translate.instant('campaign.action-alert.buttons.ok'),
                  alert = await this.alertCtrl.create({
                      header: title,
                      subHeader: message,
                      buttons: [
                          { text: ok, role: 'cancel'},
                      ],
                      mode: 'ios'
                  });
              window["app_ga"]('send', 'event', "Signup details", "Invalid link");
              alert.present();
          }
      );

  }

  addMedia(){
      if (this.status === 'DECLINED' || this.status === 'START'){
          this.override_caption = true;
          this.post_text = this.postForm.get('post_text').value;
      }
      this.navCtrl.navigateForward('/add-media/'+this.campaign_platform+'/'+this.id)
  }

  disabledCaption(){
      return this.override_caption ? false : this.postForm.get('post_text').value === this.post_text;
  }

  saveCaption(){
      let data = {
          campaigns_post_id: this.id,
          caption: this.postForm.get('post_text').value
      };
      this.post_text = this.postForm.get('post_text').value;
      this.apiService.addCaption(data).then(
          su => {
              this.caption_saved = true;
              if (this.media.length){
                  this.goBack();
              }
          }
      );

  }
  saveInsight(){
      console.log('this.id', this.id);
      let data = {
          post_id: this.id,
          story_views: null,
          reach: null,
          impressions: this.insightForm.get('impressions').value,
          insight_screenshot: this.insightForm.get('insight').value,
      };
      if (this.is_story) {
          data.story_views = this.insightForm.get('story_views').value;
      } else {
          data.reach = this.insightForm.get('reach').value;
      }

      this.apiService.add_insight(data).then(
          async su => {
              let confirmation_alert = await this.alertCtrl.create({
                  header: this.translate.instant ('media.insights-alert.title'),
                  subHeader: this.translate.instant ('media.insights-alert.subTitle'),
                  buttons: [
                      {
                          text: this.translate.instant('campaign.action-alert.buttons.cancel'),
                          role: 'cancel'
                      },
                      {
                          text: this.translate.instant('campaign.action-alert.buttons.ok'),
                          handler: data => {
                              this.navCtrl.navigateRoot('/campaign/'+this.campaign_id);
                          }
                      }
                  ],
                  mode: 'ios'
              });
              confirmation_alert.present();
          }
      );
  }

  toggle(_post) {
      _post.selected = !_post.selected;
      for (let post of this.livePosts) {
          if (post.id != _post.id) {
              post.selected = false;
          }
      }
      if (_post.selected) {
          this.selectedPost = _post;
      }
  }
}
