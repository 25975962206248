<!--
  Generated template for the MediaPage page.

  See http://ionicframework.com/docs/components/#navigation for more info on
  Ionic pages and navigation.
-->
<ion-header>

  <ion-toolbar>
    <ion-icon (click)="goBack()" name="arrow-back" ></ion-icon>
    <ion-title class="ion-text-center">{{title}}</ion-title>
  </ion-toolbar>

</ion-header>


<ion-content class="ion-padding">
<div class="instructions" *ngIf="(this.status == 'START')">{{ 'campaign.post_status.start.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'PENDING_MANAGER')">{{ 'campaign.post_status.pending-am.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'PENDING_MANAGER_CAPTION')">{{ 'campaign.post_status.pending-am.instructions-caption' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'PENDING_CUSTOMER')">{{ 'campaign.post_status.pending-cus.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'MEDIA_DECLINED'  || this.status == 'DECLINED' )">{{ 'campaign.post_status.declined-media.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'CAPTION_DECLINED' )">{{ 'campaign.post_status.declined-caption.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'POST_APPROVED')">{{ 'campaign.post_status.post-approved.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'STORY_APPROVED')">{{ 'campaign.post_status.story-approved.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'LIVE')">{{ 'campaign.post_status.complete.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'PENDING_STORY_INSIGHTS')">{{ 'campaign.post_status.pending-story-insights.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'PENDING_POST_INSIGHTS')">{{ 'campaign.post_status.pending-post-insights.instructions' | translate }}</div>
<div class="instructions" *ngIf="(this.status == 'PENDING_STORY_VIEWS')">{{ 'campaign.post_status.pending-story-views.instructions' | translate }}</div>
<div class="center" *ngIf="this.status == 'STORY_APPROVED' || this.status === 'POST_APPROVED' || this.status === 'PENDING_STORY_INSIGHTS'">
  <input *ngIf="this.swipe_link" type="text"  readonly="readonly" id="copyLink" [value]="this.swipe_link">
  <ion-button id *ngIf="this.swipe_link" id="linkBtn" data-clipboard-target="#copyLink">{{ 'media.copy-swipe' | translate }}</ion-button>
  <input *ngIf="this.tags" type="text"  readonly="readonly" id="copyTags" [value]="this.tags">
  <ion-button id *ngIf="this.tags" ="tagsBtn" data-clipboard-target="#copyTags">{{ 'media.copy-tags' | translate }}</ion-button>
</div>
<div class="app_media" *ngIf="add_media" (click)="addMedia()">
  <ion-icon *ngIf="(this.status != 'START')" class="add_media" name="add"></ion-icon>
  <div *ngIf="(this.status == 'START' && (campaign_platform === 'instagram' || campaign_platform === 'facebook'))" class="start_media ig" >
    {{ 'media.start-ig' | translate }}
  </div>
  <div *ngIf="(this.status == 'START' && campaign_platform != 'instagram' && campaign_platform != 'facebook')" class="start_media" >
    {{ 'media.start' | translate }}
  </div>
</div>
<div style="display: inline" *ngFor="let post of this.media let i = index">
  <div class="app_media" *ngIf="post.approved !='-1'" [ngClass]="mediaStatus(post.approved)" [class]="i == selected_media_index ? 'selected': ''"  (click)="declinedMedia(post.refuse_reason, post.url, post.is_video, i)">
    <div class="add_media">
      <img *ngIf="post.is_video != '1' || post.is_video != 1" src="{{post.url}}">
      <video controls *ngIf="post.is_video === '1' || post.is_video === 1" [src]="post.url"></video>
    </div>
  </div>
</div>
<h4 *ngIf="media_refused">{{ 'media.declined-media' | translate }}</h4>
<div *ngIf="selected_media_refuse_reason" style="margin-top: 10px; font-weight: bold">{{ 'media.refused_media' | translate }}</div>
<div class="refused_media" *ngIf="selected_media_refuse_reason">{{selected_media_refuse_reason}}</div>
<div style="display: inline" *ngFor="let post of this.media let i = index">
  <div class="app_media" *ngIf="post.approved ==='-1' || post.approved == -1" [ngClass]="mediaStatus(post.approved)" [class]="i == selected_media_index ? 'selected': ''"  (click)="declinedMedia(post.refuse_reason, post.url, post.is_video, i)">
    <div class="add_media">
      <img *ngIf="post.is_video != '1' || post.is_video != 1" src="{{post.url}}">
      <video controls *ngIf="post.is_video === '1' || post.is_video === 1" [src]="post.url"></video>
    </div>
  </div>
</div>
<div class="caption" *ngIf="!is_story">
  <form novalidate no-border *ngIf="edit_caption" [formGroup]="postForm">
    <b *ngIf="refuse_reason">{{ 'media.refused_caption' | translate }}</b>
    <div class="refused_caption" *ngIf="refuse_reason">{{refuse_reason}}</div>
    <ion-item>
      <ion-label position="floating" color="black">{{ 'media.post-text' | translate }}</ion-label>
      <ion-textarea type="text" formControlName="post_text"></ion-textarea>
    </ion-item>
  </form>
  <div *ngIf="!edit_caption">
    <h6 style="margin-top: 20px" >{{ 'media.post-text' | translate }}</h6>
    <p [innerHtml]="post_text"></p>
    <input type="text"  readonly="readonly" id="copyTarget" [value]="post_text">
    <ion-button id="cpyBtn" data-clipboard-target="#copyTarget">{{ 'post-modal.copy-button' | translate }}</ion-button>
  </div>
</div>
<form *ngIf="upload_story" novalidate no-border [formGroup]="storyForm">
  <ion-item>
    <strong style="font-size: 14px;">{{ 'post-modal.story-screenshot' | translate }}</strong>
    <div class="image-upload-full" (click)="is_finish = true">
      <label for="file-input-story">
        <div class="upload-image" *ngIf="!story_image">
          <div class="new-post">
            <div class="icon-post"></div>
            {{ 'post-modal.upload-image' | translate }}
          </div>
        </div>
        <img *ngIf="story_image" [src]="story_image"/>
      </label>
      <input id="file-input-story" type="file" accept="image/*" (change)="handleFileSelect($event, 'story')"/>
      <input type="hidden" formControlName="story_image">
    </div>
  </ion-item>
  <ion-item class="bordered" *ngIf="story_image">
    <ion-label >{{ 'media.views-count' | translate }}:</ion-label>
    <ion-input type="number" min="1" formControlName="views_count"></ion-input>
  </ion-item>
</form>
<form *ngIf="upload_insight" novalidate no-border [formGroup]="insightForm">
  <ion-item>
    <strong style="font-size: 14px;">{{ 'media.insight-screenshot' | translate }}</strong>
    <div class="image-upload-full" (click)="is_finish = true">
      <label for="file-input-insight">
        <div class="upload-image" *ngIf="!insight_image">
          <div class="new-post">
            <div class="icon-post"></div>
            {{ 'post-modal.upload-image' | translate }}
          </div>
        </div>
        <img *ngIf="insight_image" [src]="insight_image"/>
      </label>
      <input id="file-input-insight" type="file" accept="image/*" (change)="handleFileSelect($event, 'insight')"/>
      <input type="hidden" formControlName="insight">
    </div>
  </ion-item>
  <ion-item class="bordered" *ngIf="is_story && insight_image">
    <ion-label >{{ 'media.views-count' | translate }}:</ion-label>
    <ion-input type="number" min="1" formControlName="story_views"></ion-input>
  </ion-item>
  <ion-item class="bordered" *ngIf="insight_image && ! is_story">
    <ion-label >{{ 'media.reach' | translate }}:</ion-label>
    <ion-input type="number" min="1" formControlName="reach"></ion-input>
  </ion-item>
  <ion-item class="bordered" *ngIf="insight_image">
    <ion-label >{{ 'media.impressions' | translate }}:</ion-label>
    <ion-input type="number" min="1" formControlName="impressions"></ion-input>
  </ion-item>
</form>
<div style="margin: 10px 0;" *ngIf="choose_post && !is_finish">
  {{ 'post-modal.completed-upload' | translate:{platform: campaign_platform} }} <a (click)="is_finish = true">{{ 'post-modal.click-here' | translate }}</a>
</div>
<ion-item  *ngIf="choose_post && is_finish && campaign_platform == 'facebook'">
  <ion-label position="floating" color="black">{{ 'media.facebook-link' | translate }}</ion-label>
  <ion-input (ionChange)="setFacebookLink($event)" type="text"></ion-input>
</ion-item>
<ion-item *ngIf="choose_post && is_finish && campaign_platform !== 'facebook'">
  <div>
    <div style="font-size: 12px"> {{ 'media.choose-post' | translate }}</div>
    <div class="ion-text-center" *ngIf="!livePosts">
      <ion-spinner></ion-spinner>
    </div>
    <ion-grid>
      <ion-row>
        <ion-col size="3" *ngFor="let livePost of livePosts  ; let i= index " >
          <img *ngIf="livePost != null" [src]="livePost.image" class="{{livePost.selected ? 'selected' : ''}}" (click)="toggle(livePost)">
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-item>
<div class="actions" >
  <ion-button *ngIf="edit_caption && media.length && ! all_media_refused"  expand="block"  color="primary"  [disabled]="disabledCaption()" (click)="saveCaption()">
    {{ 'media.save-caption' | translate }}
  </ion-button>
  <ion-button *ngIf="upload_story && story_image && storyForm.get('views_count').value != null && storyForm.get('views_count').value != '' || choose_post && selectedPost || choose_post && facebook_link" expand="block" (click)="finishPost()">
    {{ 'media.finish' | translate }}
  </ion-button>
  <ion-button *ngIf="upload_insight" [disabled]="!insightForm.valid"  expand="block"  color="primary" (click)="saveInsight()">
    {{ 'media.send-insights' | translate }}
  </ion-button>
</div>



</ion-content>
