/* src/app/pages/media/media.page.scss */
h4 {
  font-size: 1.6rem;
  font-weight: bold;
}
.instructions {
  padding: 5px;
  background: #4a7692;
  color: #fff;
}
.refused_caption,
.refused_media {
  padding: 5px;
  background: #b40412;
  color: #fff;
}
.back-button {
  margin: 0;
  min-width: 0;
  position: absolute;
  z-index: 99999;
}
.app_media {
  height: 120px;
  border: #cecece 1px solid;
  width: 32.6%;
  vertical-align: middle;
  margin-top: 5px;
  position: relative;
  color: #333333;
  line-height: 120px;
  box-shadow: 1px 1px 10px -4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.add_image {
  font-size: 3em;
  position: absolute;
  height: 50%;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px dotted;
}
.add_video {
  font-size: 3em;
  position: absolute;
  height: 50%;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_media {
  text-align: center;
  font-size: 4.2em;
  vertical-align: middle;
  line-height: 105px;
  width: 100%;
}
.add_media video {
  width: 100%;
  vertical-align: middle;
}
.add_media img {
  vertical-align: middle;
}
.start_media {
  background: url("./media/post-icon-video.png");
  background-position-y: -5px;
  background-position-x: center;
  font-size: 1em;
  line-height: 1.2;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  color: #666666;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.start_media.ig {
  background: url("./media/start-image.png");
  background-position-y: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #eeeeee;
}
#copyTarget,
#copyLink,
#copyTags {
  position: absolute;
  right: 9999px;
}
#cpyBtn,
#linkBtn,
#tagsBtn {
  text-transform: none;
  background-color: #a3afc7;
  margin: 10px 0;
  box-shadow: none;
}
.center {
  text-align: center;
}
.upload-image {
  background-color: #eee;
  height: 150px;
  position: relative;
  border: 1px dotted #e0dcdc;
}
.upload-image .new-post {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.2;
  text-transform: uppercase;
}
.upload-image .new-post .icon-post,
.upload-image .new-post .icon-video-post,
.upload-image .new-post .icon-story,
.upload-image .new-post .icon-video-story {
  height: 50px;
  width: 50px;
  margin: 0 auto;
  margin-bottom: 10px;
  background-size: cover;
}
.image-upload-full {
  width: 100%;
  float: left;
  text-align: center;
  height: 200px;
}
.image-upload-full img {
  height: 100%;
}
input#file-input-story,
input#file-input-insight {
  display: none;
}
.media_declined.selected {
  border: #b40412 2px solid;
}
.media_approved:after {
  text-align: center;
  background-color: #bbffc0;
  position: absolute;
  color: #008800;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  pointer-events: none;
}
.media_declined:after {
  text-align: center;
  background-color: #ffbbc0;
  position: absolute;
  color: #880000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  pointer-events: none;
}
form {
  margin-top: 20px;
}
.badge {
  border-radius: 3px;
  background-color: #6d747e;
  padding: 4px;
  font-weight: 300;
  min-width: 50px;
  font-size: 12px;
}
.badge.post-start {
  background-color: #ff687e;
}
.badge.post-declined {
  background-color: #f30d0d;
}
.badge.post-approved {
  background-color: var(--primary);
}
.badge.post-pending {
  background-color: #d29e04;
}
.badge.post-completed {
  background-color: #2aa646;
}
ion-grid ion-row ion-col img {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  outline: none;
  padding: 3px 3px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid #ddd;
}
ion-grid ion-row ion-col img.selected {
  box-shadow: 0 0 5px rgb(81, 203, 238);
  padding: 3px 3px 3px 3px;
  margin: 5px 1px 3px 0px;
  border: 1px solid rgb(81, 203, 238);
}
/*# sourceMappingURL=media.page.css.map */
